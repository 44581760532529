r<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <AConfirmation :isShow="isPendingSwapCompany" @cancel="cancelSubmit"
        @confirm="updateCurrentCompany"/>
        <ASuccessFour :api="this.api"/>
        <div class="d-flex justify-start">
            <v-btn @click="()=>{
                this.$router.go(-1);
            }" class="mb-3" color="primary" >
                <v-icon>
                    mdi-arrow-left-bold
                </v-icon>
            </v-btn>
        </div>
        <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table">

        </v-skeleton-loader>
        <v-card v-else>
            <v-toolbar class="white--text text-h5 mb-4" color="secondary">
                User Swap Company
            </v-toolbar>
            <div  v-for="(item,index) in users" :key="index">
                <v-card class="ma-5 pa-2">
                    <div class="d-flex justify-start mx-2 mt-4">
                        <p class="text-h5 font-weight-bold">
                            User {{ index+1 }}
                        </p>
                    </div>
                    <v-row no-gutters >
                        <v-col cols="12" sm="12">
                            <div class="px-2">
                                <div class="pr-3">
                                    <v-text-field v-model="item.user_name" label="First Name" dense outlined disabled>
            
                                    </v-text-field>
        
                                </div>
                                <div>
                                    <v-text-field v-model="item.user_lname" label="Last Name" dense outlined disabled>
        
                                    </v-text-field>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-text-field disabled v-model="item.user_email" label="Email" dense outlined>
                                        
                                    </v-text-field>                            
                                </div>
                            </div>
        
                        </v-col>
                    </v-row>
                   <div class="d-flex px-2">
                        <v-text-field v-model="companies" disabled dense outlined>
                        </v-text-field>

                   </div>
                    <div class="d-flex px-2" v-if="companiesAvailable!=null">
                        <v-autocomplete dense outlined 
                        v-model="companiesAvailableSelected"
                        :items="companiesAvailable" 
                        @change="validateInputUpdateCurrentCompany(item.user_id)"
                        label="Swap Company" return-object item-text="tempName">
                            <!-- <template v-slot:item="{item}">
                                <span>
                                    {{ item.company_name }} ( {{ item.company_id }} ,{{ item.acc_category }} )
                                </span>
                            </template> -->
                            <!-- <template v-slot:selection="{item}">
                                <span>
                                    {{ item.company_name }} ({{ item.company_id }}, {{ item.acc_category }} )
                                </span>
                            </template> -->
                            <!-- <template v-slot:item.selection="{item}">
                                <span>
                                </span>
                            </template> -->
                        </v-autocomplete>
                    </div>
                </v-card>
            </div>
            <div class="d-flex justify-end pa-5">

                <v-btn color="primary" plain @click="()=>{
                    this.$router.go(-1);
                }">
                    Cancel
                </v-btn>
                <v-btn color="primary" @click="()=>{
                    this.isPending =true;
                }">
                    Confirm
                </v-btn>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';

export default{
    data: () => ({
        search:'',
        isPendingSwapCompany:false,
        isPending: false,
        companies: null,
        tempUserId:null,
        companiesAvailable:null,
        companiesAvailableSelected:null,
        tempCompaniesAvailableSelected:null,
        users: null,
        isVerified: false,
        isVerifiedCustomValue: 'Verified',
        isNotVerifiedCustomValue: 'Not Verified',
        isEnable: false,
        isEnableCustomValue: 'Enable',
        isNotEnableCustomValue: 'Disable',
        AccountUserOptions:['primary',"secondary"],
        MainCategoryOptions : ["SmartCFO Client","TaxPOD Client","Internal Staff"],
        RoleOptions: ["User", 'Admin', "Instructor","Manager","Consultant","Tax Expert"],
        AAERoleOptions:["client","consultant","tax expert","admin","manager"],
        api: {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        }
    }),
    computed: {
        filteredSwapCompanies(){
            let _search = this.$_.clone(this.companiesAvailableSelected);
            return this.companiesAvailable.filter((item)=>{
                return [
                    item.company_name.includes(_search) || item.acc_category.includes(_search)
                ];
            })
        },
        ComputeCompanyIsVerified(verified_at) {
            return (verified_at != null) ? true : false;
        },
        MappingSwitchIsVerified: {
            get(tempIsVerified) {
                return tempIsVerified == true ? this.isVerifiedCustomValue : this.isNotVerifiedCustomValue;
            },
            set(value) {
                this.isVerified = value === this.isVerifiedCustomValue;
            }
        },
        MappingSwitchIsEnable: {
            get() {
                return this.isEnable ? this.isEnableCustomValue : this.isNotEnableCustomValue;
            },
            set(value) {
                this.isEnable = value === this.isEnableCustomValue;
            },
        }
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getCompanyFullInformation") {
                // let tempCompany = {
                //     "company_name": tempData.company_name,
                //     "company_id": tempData.company_id,
                //     "company_address": tempData.address,
                //     "company_ssm": tempData.ssm_no,
                //     "company_industry": tempData.industry,
                //     "company_branch": tempData.branch,
                //     "company_subscription_type": tempData.subscription_type,
                //     "company_coupon": tempData.coupon,
                // };
                // this.companies = tempCompany;
                let tempData = resp.data[0];
                let tempCompany = tempData.company_name+" ( "+tempData.company_id+" , "+tempData.acc_category+" )";
                this.companies = tempCompany;
                let tempUserVerifiedSwitch = [];
                let tempUserDisabledSwitch = [];
                let users = [];
                resp.data.forEach(data => {
                    let tempUser = {
                        "user_id":data.user_id,
                        "user_name": data.user_name,
                        "user_lname": data.user_lname,
                        "user_email": data.email,
                        "user_mobile": data.mobile,
                        "user_role": data.user_role,
                        "user_subscription_type": data.subscription_type,
                        "user_branch": data.branch,
                        "user_verified_at": data.email_verified_at,
                        "user_status": data.user_status,
                        "user_account_type":data.acc_category,
                        "user_aae_role":data.aae_role_name,
                    };
                    tempUserDisabledSwitch.push(data.user_status != 0) ? true : false,
                        tempUserVerifiedSwitch.push(data.email_verified_at != null ? true : false);
                    users.push(tempUser);
                });
                this.isEnable = tempUserDisabledSwitch;
                this.isVerified = tempUserVerifiedSwitch;
                this.users = users;
            }
            if(resp.class==="getCompaniesClientActive"){
                let tempCompanies = resp.data.companies.filter(function(item){
                    if(item.verification!='Expired'){
                        return true;
                    }
                })
                let tempCompaniesAvailable = [];
                tempCompanies.forEach(data => {
                    let tempName = data.company_name+" ( "+data.company_id+" , "+data.acc_category+" )"; 
                    let temp = {
                        'tempName':tempName,
                        'company_id':data.company_id,
                    }
                    tempCompaniesAvailable.push(temp);
                });
                this.companiesAvailable = tempCompaniesAvailable;
                
            }

            if(resp.class=="updateCompanyFullInformation"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Company Detail";
            }
            if(resp.class=="updateCurrentCompany"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully swap user to other company";
            }
            // What I have don yesterday
            // Finish the active task module. With an adjustment from
            // Hanis design. Today got a new enhancement request from xin ying 
            //
            this.api.isLoading = false;
            this.api.isError = false;
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchCompaniesDetailApi = this.fetchCompaniesDetail();
            this.$api.fetch(fetchCompaniesDetailApi);

            if(this.$store.getters.getRole.includes('developer')){
                let fetchCompaniesApi = this.fetchCompanies();
                this.$api.fetch(fetchCompaniesApi);
            }
        },
        fetchCompaniesDetail() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/company/full/client/id/" + this.$router.history.current.params.id;
            return tempApi;
        },
        fetchCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/subscription/Active?role="+this.$store.getters.getRole+"&userId="+this.$store.getters.getUserId;
            return tempApi;
        },
        validateInput() {
            this.isPending = true;
        },
        validateInputUpdateCurrentCompany(userId){
            this.tempUserId = this.$_.clone(userId);
            this.isPendingSwapCompany = true;
        },
     
        submit(){
            this.isPending = false;
            let formData = new FormData;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/user/client/company/"+this.$router.history.current.params.id;
            // formData.append("company_name",this.companies.company_name);
            // formData.append("company_address",this.companies.company_address);
            // formData.append("company_coupon",this.companies.company_coupon);
            // formData.append('company_ssm',this.companies.company_ssm);
            // formData.append('company_industry',this.companies.company_industry);
            // formData.append('company_branch',this.companies.company_branch);
            // formData.append('subscription_type',this.companies.company_subscription_type);
            // formData.append('company_coupon',this.companies.company_coupon);

            

            tempApi.params = formData;

            this.$api.fetch(tempApi);

        },
        cancelSubmit(){
            this.isPending = false;
            this.isPendingSwapCompany = false;
            this.companiesAvailableSelected = null;
        }, 
        updateCurrentCompany(){
            this.isPendingSwapCompany = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            let tempCompanyId = this.$_.clone(this.companiesAvailableSelected.company_id);
            let tempUserId = this.$_.clone(this.tempUserId)
            tempApi.url = process.env.VUE_APP_SERVER_API+"/user/company";
            tempApi.params = {
                'id':tempUserId,
                'companyId':tempCompanyId,
            };
            this.$api.fetch(tempApi);

        } 
    },
    components: { AConfirmation,ASuccessFour }
}
</script>